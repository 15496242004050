@media (max-width: 1024px) {
  #header {
    height: $header-height-mobile;
    padding: 0;
    padding: 2rem;

    #logo img {
      max-width: $logo-width-mobile;
      max-width: 48px;
    }
  }
  h2 {
    height: 60px;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    margin-top: 6rem;
    top: 70px;
  }

  .chess .chess-row {
    > .img-container {
      order: 0;
      &::after {
        display: none;
      }
    }
    > .txt-container {
      order: 1;
      > p + p {
        margin-top: 2rem;
        display: none;
      }
    }

    > .txt-container.mobile-only {
      order: -1;
    }

    > .img-container,
    > .txt-container {
      grid-column: span 5;
      text-align: left !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .mouse_scroll {
    margin-top: 25px;
  }

  .modal-container > .img-container {
    width: 96%;
    height: 96%;
  }
}
