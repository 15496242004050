.modal-container {
    transition: top 300ms ease-in-out, left 600ms ease-in-out, background-color 300ms ease-in-out, width 700ms ease-out, height 700ms ease-out;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    //background-color: $semidark-transparent;
    cursor: pointer;
    z-index: 5;
    /*> img {
        width: auto;
        max-width: 40%;
        height: auto;
    }*/
    > .img-container {
        width: 90%;
        height: 90%;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        > .loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.opened {
        width: 100% !important;
        height: 100% !important;
        top: 0 !important;
        left: 0 !important;
        background-color: $light;
    }

    .close-btn {
        position: absolute;
        top: 0;
        right: 0;
        background-image: url('../images/icon-close.png');
        width: 64px;
        height: 64px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
}