* {
  box-sizing: border-box;
  font-family: "Barlow", sans-serif;
  letter-spacing: 0.15rem;
}

html {
  scroll-behavior: smooth;
  font-size: 18px;
}

body {
  font-family: "BatonTurbo";
  text-rendering: optimizeLegibility;

  &.modal-open {
    overflow: hidden;
  }
}

.centered {
  text-align: center;
}

footer > * {
  letter-spacing: normal;
}

@media all and (max-width: 768px) {
  html {
    font-size: 13px;
  }

  .left-on-mobile {
    text-align: left;
  }
}

.mobile-only {
  display: none !important;
  @media all and (max-width: 768px) {
    & {
      display: block !important;
    }
  }
}

h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: $dark;
  //position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $light;
  padding: 1.5rem 0;
  height: $header-height;
  z-index: 4;
}
