#header {
  position: fixed;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  height: $header-height;
  background-color: $light;

  #logo {
    text-align: center;
  }

  #logo img {
    width: 100%;
    max-width: $logo-width;
  }
}
