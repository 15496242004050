.bg-primary {
    background-color: $primary;
}

.txt-dark {
    color: $dark;
}

.txt-semidark {
    color: $semidark;
}

.txt-light {
    color: $light;
}