.container-full {
    width: 100%;
    overflow: hidden;
}

.container {
    width: 100%;
    overflow: hidden;
    max-width: 1280px;
    padding: 4rem;
    margin-left: auto;
    margin-right: auto;

    

  @media all and (max-width: 768px) {
      & {
          padding: 2rem;
      }
  }
}

section.container {
    content-visibility: auto;
}

section.container:first-of-type {
    content-visibility: visible;
}