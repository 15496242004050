article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(80vh - 120px);
  max-height: 80vh;
  color: $semidark;
  line-height: 1.2rem;

  p {
    margin-bottom: 2rem;
  }

  a {
    overflow: hidden;
    position: relative;
    color: $dark;
    text-decoration: none !important;
    //border-bottom: 2px solid #1f1f1f;
    opacity: 1;
    padding: 3px 5px;
    transition: all $animations-time ease-in-out;

    &:hover {
      background-color: $primary;
      color: $light;
      //border-bottom-color: $primary;

      &::after {
        width: 100% !important;
      }
    }

    &::after {
      transition: width $animations-time ease-in-out;
      animation: article-anchor 3.5s infinite alternate;
      animation-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
      content: "";
      display: block;
      width: 12px;
      height: 1px;
      background-color: $semidark;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &.txt-big {
    p {
      font-size: 125%;
      line-height: 110%;
    }
  }

  & p.txt-big {
    font-size: 125%;
    line-height: 110%;
  }
}

@keyframes article-anchor {
  0% {
    width: 12px;
  }
  /*50% {left: 25%; width: 16px;}
  100% {left: calc(100% - 12px); width: 12px;}*/
  100% {
    width: 100%;
  }
}
