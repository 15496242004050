@font-face {
  font-family: "BatonTurbo";
  src: url("./fonts/BatonTurboWeb-Regular.eot");
  src: url("./fonts/BatonTurboWeb-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/BatonTurboWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "BatonTurbo";
  src: url("./fonts/BatonTurboWeb-Medium.eot");
  src: url("./fonts/BatonTurboWeb-Medium.eot?#iefix") format("embedded-opentype"), url("./fonts/BatonTurboWeb-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
