.loader {
    width: 60px;
  }
  
  .loader-wheel {
    animation: spin 1s infinite linear;
    border: 2px solid rgba(30, 30, 30, 0.5);
    border-left: 4px solid #fff;
    border-radius: 50%;
    height: 50px;
    margin-bottom: 10px;
    width: 50px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }