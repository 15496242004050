.chess {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  hr {
    background-color: $semidark;
    width: 100%;
  }
  .chess-row {
    display: grid;
    min-height: 40vh;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    line-height: 1.2rem;
    > * {
      height: 100%;
    }

    > .img-container {
      overflow: hidden;
      opacity: 0.8;
      grid-column: span 2;
      display: grid;
      position: relative;
      /*align-items: flex-start;
      justify-content: center;*/
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      transition: opacity $animations-time ease-in-out;
      > img {
        cursor: pointer;
        width: auto;
        max-width: 100%;
        transition: transform $animations-time ease-in-out;
      }

      .icon-zoom {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        transform: translate(-50%, -50%);
        background-image: url("../images/icon-zoom.png");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        transition: opacity $animations-time ease-in-out;
        width: 96px;
        height: 96px;
        pointer-events: none;
      }

      &::after {
        position: absolute;
        top: 0;
        content: "";
        display: block;
        width: 4px;
        height: 72px;
        background-color: $semidark;
        transition: all $animations-time ease-in-out;
      }

      &:first-child {
        padding-right: 12px;
        &::after {
          right: 0;
        }
      }

      &:nth-child(2) {
        padding-left: 12px;
        &::after {
          left: 0;
        }
      }
      &:hover {
        opacity: 0.8;
        img:hover {
          z-index: 2;
          transform: scale(1.2);
          & ~ .icon-zoom {
            opacity: 1;
          }
          & + .icon-zoom {
            top: 75%;
            left: 75%;
          }
          & + img + .icon-zoom {
            top: 75%;
            left: 25%;
          }
          & + img + img + .icon-zoom {
            top: 25%;
            left: 75%;
          }
          & + img + img + img + .icon-zoom {
            top: 25%;
            left: 25%;
          }
        }

        &::after {
          background-color: $secondary;
          //width: 12px;
        }
      }
    }
    > .txt-container {
      grid-column: span 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: $semidark;
      transition: color $animations-time ease-in-out;

      p {
        &:last-child {
          font-size: 1.3rem;
          color: $semidark;
          transition: color $animations-time ease-in-out;
          strong {
            color: $dark;
            transition: color $animations-time ease-in-out;
            font-weight: $font-bold;
          }
        }
      }
    }

    > .img-container + .txt-container {
      text-align: right;
    }

    > .txt-container + .img-container {
      text-align: right;
    }

    &:hover {
      > .txt-container {
        color: $dark;
        p:last-child,
        p:last-child strong {
          color: $semidark-transparent;
        }
      }
    }
  }
}
