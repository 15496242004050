tml, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.container-full {
  width: 100%;
  overflow: hidden;
}

.container {
  width: 100%;
  overflow: hidden;
  max-width: 1280px;
  padding: 4rem;
  margin-left: auto;
  margin-right: auto;
}

@media all and (max-width: 768px) {
  .container {
    padding: 2rem;
  }
}

section.container {
  content-visibility: auto;
}

section.container:first-of-type {
  content-visibility: visible;
}

@font-face {
  font-family: "BatonTurbo";
  src: url("BatonTurboWeb-Regular.797b04d4.eot");
  src: url("BatonTurboWeb-Regular.797b04d4.eot#iefix") format("embedded-opentype"), url("BatonTurboWeb-Regular.f078c8b0.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BatonTurbo";
  src: url("BatonTurboWeb-Medium.297f1ded.eot");
  src: url("BatonTurboWeb-Medium.297f1ded.eot#iefix") format("embedded-opentype"), url("BatonTurboWeb-Medium.218236a7.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: "Barlow", sans-serif;
  letter-spacing: 0.15rem;
}

html {
  scroll-behavior: smooth;
  font-size: 18px;
}

body {
  font-family: "BatonTurbo";
  text-rendering: optimizeLegibility;
}

body.modal-open {
  overflow: hidden;
}

.centered {
  text-align: center;
}

footer > * {
  letter-spacing: normal;
}

@media all and (max-width: 768px) {
  html {
    font-size: 13px;
  }

  .left-on-mobile {
    text-align: left;
  }
}

.mobile-only {
  display: none !important;
}

@media all and (max-width: 768px) {
  .mobile-only {
    display: block !important;
  }
}

h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #1f1f1f;
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
  padding: 1.5rem 0;
  height: 140px;
  z-index: 4;
}

.bg-primary {
  background-color: #006871;
}

.txt-dark {
  color: #1f1f1f;
}

.txt-semidark {
  color: #717171;
}

.txt-light {
  color: white;
}

#header {
  position: fixed;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  height: 140px;
  background-color: white;
}

#header #logo {
  text-align: center;
}

#header #logo img {
  width: 100%;
  max-width: 102px;
}

footer {
  margin-top: 6rem;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 1rem;
  /*@media all and (max-width: 768px) {
      & {
        font-size: 1.2rem;
      }
    }*/
}

footer * {
  letter-spacing: 0;
}

footer img {
  width: 0.9rem;
}

footer p,
footer a {
  color: #717171;
}

footer a {
  text-decoration: none;
  margin-right: 0.5rem;
}

footer a:last-child {
  margin-right: 0;
}

article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(80vh - 120px);
  max-height: 80vh;
  color: #717171;
  line-height: 1.2rem;
}

article p {
  margin-bottom: 2rem;
}

article a {
  overflow: hidden;
  position: relative;
  color: #1f1f1f;
  text-decoration: none !important;
  opacity: 1;
  padding: 3px 5px;
  transition: all 400ms ease-in-out;
}

article a:hover {
  background-color: #006871;
  color: white;
}

article a:hover::after {
  width: 100% !important;
}

article a::after {
  transition: width 400ms ease-in-out;
  animation: article-anchor 3.5s infinite alternate;
  animation-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  content: "";
  display: block;
  width: 12px;
  height: 1px;
  background-color: #717171;
  position: absolute;
  left: 0;
  bottom: 0;
}

article.txt-big p {
  font-size: 125%;
  line-height: 110%;
}

article p.txt-big {
  font-size: 125%;
  line-height: 110%;
}

@keyframes article-anchor {
  0% {
    width: 12px;
  }
  /*50% {left: 25%; width: 16px;}
  100% {left: calc(100% - 12px); width: 12px;}*/
  100% {
    width: 100%;
  }
}

.chess {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.chess hr {
  background-color: #717171;
  width: 100%;
}

.chess .chess-row {
  display: grid;
  min-height: 40vh;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  line-height: 1.2rem;
}

.chess .chess-row > * {
  height: 100%;
}

.chess .chess-row > .img-container {
  overflow: hidden;
  opacity: 0.8;
  grid-column: span 2;
  display: grid;
  position: relative;
  /*align-items: flex-start;
  justify-content: center;*/
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  transition: opacity 400ms ease-in-out;
}

.chess .chess-row > .img-container > img {
  cursor: pointer;
  width: auto;
  max-width: 100%;
  transition: transform 400ms ease-in-out;
}

.chess .chess-row > .img-container .icon-zoom {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transform: translate(-50%, -50%);
  background-image: url("icon-zoom.871fc3e0.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: opacity 400ms ease-in-out;
  width: 96px;
  height: 96px;
  pointer-events: none;
}

.chess .chess-row > .img-container::after {
  position: absolute;
  top: 0;
  content: "";
  display: block;
  width: 4px;
  height: 72px;
  background-color: #717171;
  transition: all 400ms ease-in-out;
}

.chess .chess-row > .img-container:first-child {
  padding-right: 12px;
}

.chess .chess-row > .img-container:first-child::after {
  right: 0;
}

.chess .chess-row > .img-container:nth-child(2) {
  padding-left: 12px;
}

.chess .chess-row > .img-container:nth-child(2)::after {
  left: 0;
}

.chess .chess-row > .img-container:hover {
  opacity: 0.8;
}

.chess .chess-row > .img-container:hover img:hover {
  z-index: 2;
  transform: scale(1.2);
}

.chess .chess-row > .img-container:hover img:hover ~ .icon-zoom {
  opacity: 1;
}

.chess .chess-row > .img-container:hover img:hover + .icon-zoom {
  top: 75%;
  left: 75%;
}

.chess .chess-row > .img-container:hover img:hover + img + .icon-zoom {
  top: 75%;
  left: 25%;
}

.chess .chess-row > .img-container:hover img:hover + img + img + .icon-zoom {
  top: 25%;
  left: 75%;
}

.chess .chess-row > .img-container:hover img:hover + img + img + img + .icon-zoom {
  top: 25%;
  left: 25%;
}

.chess .chess-row > .img-container:hover::after {
  background-color: #78261c;
}

.chess .chess-row > .txt-container {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #717171;
  transition: color 400ms ease-in-out;
}

.chess .chess-row > .txt-container p:last-child {
  font-size: 1.3rem;
  color: #717171;
  transition: color 400ms ease-in-out;
}

.chess .chess-row > .txt-container p:last-child strong {
  color: #1f1f1f;
  transition: color 400ms ease-in-out;
  font-weight: 700;
}

.chess .chess-row > .img-container + .txt-container {
  text-align: right;
}

.chess .chess-row > .txt-container + .img-container {
  text-align: right;
}

.chess .chess-row:hover > .txt-container {
  color: #1f1f1f;
}

.chess .chess-row:hover > .txt-container p:last-child,
.chess .chess-row:hover > .txt-container p:last-child strong {
  color: rgba(113, 113, 113, 0.4);
}

.modal-container {
  transition: top 300ms ease-in-out, left 600ms ease-in-out, background-color 300ms ease-in-out, width 700ms ease-out, height 700ms ease-out;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  z-index: 5;
  /*> img {
      width: auto;
      max-width: 40%;
      height: auto;
  }*/
}

.modal-container > .img-container {
  width: 90%;
  height: 90%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.modal-container > .img-container > .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-container.opened {
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  background-color: white;
}

.modal-container .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-image: url("icon-close.068320f7.png");
  width: 64px;
  height: 64px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.mouse_scroll {
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 125px;
}

.mouse_scroll .m_scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  border-right: 2px solid #717171;
  border-bottom: 2px solid #717171;
  margin: 0 0 3px 4px;
  width: 16px;
  height: 16px;
}

.mouse_scroll .unu {
  margin-top: 1px;
}

.mouse_scroll .unu,
.mouse_scroll .doi,
.mouse_scroll .trei {
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
  animation: mouse-scroll 1s infinite;
}

.mouse_scroll .unu {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  animation-delay: alternate;
}

.mouse_scroll .doi {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -webkit-animation-direction: alternate;
  animation-delay: 0.2s;
  animation-direction: alternate;
  margin-top: -6px;
}

.mouse_scroll .trei {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -webkit-animation-direction: alternate;
  animation-delay: 0.3s;
  animation-direction: alternate;
  margin-top: -6px;
}

.mouse_scroll .mouse {
  height: 42px;
  width: 24px;
  border-radius: 14px;
  transform: none;
  border: 2px solid #717171;
}

.mouse_scroll .wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: #717171;
  position: relative;
  height: 4px;
  width: 4px;
  border: 2px solid #717171;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.mouse_scroll .wheel {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite;
}

@-webkit-keyframes mouse-wheel {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}

@-moz-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@-o-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.loader {
  width: 60px;
}

.loader-wheel {
  animation: spin 1s infinite linear;
  border: 2px solid rgba(30, 30, 30, 0.5);
  border-left: 4px solid #fff;
  border-radius: 50%;
  height: 50px;
  margin-bottom: 10px;
  width: 50px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  #header {
    height: 100px;
    padding: 0;
    padding: 2rem;
  }
  #header #logo img {
    max-width: 84px;
    max-width: 48px;
  }

  h2 {
    height: 60px;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    margin-top: 6rem;
    top: 70px;
  }

  .chess .chess-row > .img-container {
    order: 0;
  }
  .chess .chess-row > .img-container::after {
    display: none;
  }
  .chess .chess-row > .txt-container {
    order: 1;
  }
  .chess .chess-row > .txt-container > p + p {
    margin-top: 2rem;
    display: none;
  }
  .chess .chess-row > .txt-container.mobile-only {
    order: -1;
  }
  .chess .chess-row > .img-container,
.chess .chess-row > .txt-container {
    grid-column: span 5;
    text-align: left !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mouse_scroll {
    margin-top: 25px;
  }

  .modal-container > .img-container {
    width: 96%;
    height: 96%;
  }
}
/*# sourceMappingURL=index.21d69bb9.css.map */
