footer {
  margin-top: 6rem;
  font-size: 0.9rem;
  font-weight: $font-semibold;
  line-height: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 1rem;

  * {
    letter-spacing: 0;
  }

  /*@media all and (max-width: 768px) {
      & {
        font-size: 1.2rem;
      }
    }*/

  img {
    width: 0.9rem;
  }

  p,
  a {
    color: $semidark;
  }

  a {
    text-decoration: none;
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
